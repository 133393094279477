import React, { useState } from 'react';
import './App.css';
import Dishes from './Dishes';

function App() {
  const [ingredient, setIngredient] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const newIngredient = event.target.elements.ingredient.value;
    setIngredient(newIngredient);
  };

  return (
    <>
    <div className='main'>
    <h1 className='title'>LetMeCook</h1>
      <p>Cauta preparate ce contin un anumit ingredient.</p>
      <div className='form'>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="ingredient"
            placeholder="Introduceți ingredientul"
          />
          <button type="submit" className="search-button">Caută</button>
        </form>
      </div>
      </div>
      <div className="main-container">
        <Dishes ingredient={ingredient} />
      </div>
    </>
  );
}

export default App;
