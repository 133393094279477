import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

function Dishes({ ingredient }) {
    const [post, setPost] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!ingredient) {
            setPost([]);
            setError("Introduceti denumirea igredientului in engleza.");
            return;
        }

        setLoading(true);
        setError(null);

        axios.get("https://letmecook.pavalsebastian.com:5003/Food?ingredient=" + ingredient)
            .then((data) => {
                if (data?.data && data.data.length > 0) {
                    setPost(data.data);
                } else {
                    setPost([]);
                    setError("Nu au fost gasite preparate.");
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error("Eroare la preluarea datelor:", error);
                setPost([]);
                setError("A apărut o eroare la preluarea datelor");
                setLoading(false);
            });
    }, [ingredient]);

    return (
        <>
            {loading ? (
                <p>Se încarcă...</p>
            ) : error ? (
                <p>{error}</p>
            ) : post.length > 0 ? (
                post.map((item, i) => (
                    <div key={i}>
                        <p>{item?.name} -<span className='rating'> {item?.rating}&#11088;</span></p>
                    </div>
                ))
            ) : (
                <p>Nu s-a găsit niciun preparat pentru ingredientul specificat</p>
            )}
        </>
    );
}

export default Dishes;
